const colors = {
  alabaster: '#EFEFEF',
  alto: '#D8D8D8',
  gray: '#8D8D8D',
  red: '#E2635D',
  redlight: '#F3C1BE',
  slate: '#202020',
  white: '#FFF',
  softblue: '#2d9be0',
  black: '#000000',

  // New color scheme as of July - see DX-601
  openingHours: '##859395',
  iconColor: '#859395',
  cardItem: '#455255',
  brandPage: '#455255',
  sagedark: '#455255',
  fontMainColor: '#000000',
  readMoreArrowIcon: '#000000',
  grayToDark: '#8D8D8D',
  buttonColor: '#EFEFEF',
  lifeStyle: '#FFF',
  sagemedium: '#859395',
  btnPromo: '#859395',
  ctaBlock: '#859395',
  sagelight: '#F0F0F0',
  sagefeather: '#F7F7F7',
  sagefeatherToSandStone: '#F7F7F7',
  sagefeatherToBlack: '#F7F7F7',
  coaldark: '#313131',
  coalmedium: '#4E4A4A',
  coallight: '#989393',

  // Membership colors
  boxBackground: '#f6f3f3',
  nonActive: '#c6c6c6',
  dark: '#575756',
  level1Colour: '#033c6d',
  level2Colour: '#ca5b3f',
  level3Colour: '#004428',

  emergencyBannerBackground: '#a52433',
  relatedArticlesBackground: '#191845',

  // New colors for new offers page -- see user Story 87067
  pastelTeal: '#889494',
  teal: '#556967',

  // New colors for the new Product/Gift Guide Template -- See Story 90099
  pastelGold: '#BBA88E',
  gold: '#AD9667',
  kobe: '#8D2614',
  coolBlack: '#082265',

  backgroundColor: 'white',
  overlayBackground: 'rgba(255, 255, 255, 0.9)',
  btnMainColor: '#455255',
  primary: '#455255',
  primaryBGBtn: 'white',
  secondaryFont: '#859395', // sagemedium
  secondary: '#F7F7F7',
  terciaryFont: '#455255', // sagedark
  quaternaryFont: '#F0F0F0', // sagelight
  alternativeFont: 'black',
  sagelightToGreen: '#F0F0F0',
  sagefeatherToGreen: '#F7F7F7',
  whiteToGreen: '#FFFFFF',
  sagemediumToGreen: '#859395',
  sagelightToBG: '#F0F0F0',
  sagemediumToRacingGreen: '#859395',
  whiteToSandstone: '#FFFFFF',
  sagedarkToSandstone: '#455255',

  sagemediumToBlack: '#859395',
  sagedarkToblack: '#455255',
  // Shopping Package collor addiition
  whiteChocolate: '#EDE0D9',
  darkGold: '#8A6C45',
  midGray: '#BBB7AD',
  beaver: '#A1896A',
  darkTeal: '#434341',
  divisor: '#bbb7ad',
  lightShadow: '#d3dae2',

  // colors for Partners Service and Offers
  carouselDot: 'rgba(0,0,0,0.2)',
  carouselActiveDot: 'rgba(0,0,0,0.5)',
  darkToGreen: '#575756',
  coaldarkToBlack: '#313131',
  coallightToBlack: '#989393',
}

export const colorsVillage = {
  alabaster: '#EFEFEF',
  buttonColor: '#7E8A4A',
  alto: '#D8D8D8',
  gray: '#8D8D8D',
  red: '#E2635D',
  redlight: '#F3C1BE',
  slate: '#F5F0E6',
  white: '#FFF',
  softblue: '#2d9be0',
  black: '#000000',

  // New color scheme as of July - see DX-601
  iconColor: '#455255',
  sagedark: '#7E8A4A',
  cardItem: '#000000',
  brandPage: '#000000',
  grayToDark: '#000000',
  fontMainColor: '#000000',
  readMoreArrowIcon: '#F5F0E6',
  lifeStyle: '#000000',
  sagemedium: '#000000',
  openingHours: '##859395',
  btnPromo: '#F5F0E6',
  ctaBlock: '#000000',
  sagelight: '#7E8A4A',
  sagefeather: '#7E8A4A',
  sagefeatherToBlack: '#000000',
  sagefeatherToSandStone: '#F5F0E6',
  coaldark: '#7E8A4A',
  coalmedium: '#7E8A4A',
  coallight: '#7E8A4A',

  // Membership colors
  boxBackground: '#7E8A4A',
  nonActive: '#b9bb97',
  dark: '#F5F0E6',
  level1Colour: '#F5F0E6',
  level2Colour: '#F5F0E6',
  level3Colour: '#F5F0E6',

  emergencyBannerBackground: '#a52433',
  relatedArticlesBackground: '#191845',

  // New colors for new offers page -- see user Story 87067
  pastelTeal: '#889494',
  teal: '#556967',

  // New colors for the new Product/Gift Guide Template -- See Story 90099
  pastelGold: '#BBA88E',
  gold: '#AD9667',
  kobe: '#8D2614',
  coolBlack: '#082265',

  backgroundColor: '#F5F0E6',
  overlayBackground: 'rgba(245, 240, 230, 0.9)',
  btnMainColor: '#7E8A4A',
  primary: '#F5F0E6',
  primaryBGBtn: 'white',
  secondary: '#7E8A4A',
  secondaryFont: '#F5F0E6',
  terciaryFont: '#f5f0e6',
  quaternaryFont: '#f5f0e6', // sagemedium,
  alternativeFont: '#f5f0e6',
  sagelightToGreen: '#7E8A4A',
  sagefeatherToGreen: '#7E8A4A',
  whiteToGreen: '#7E8A4A',
  sagemediumToGreen: '#7E8A4A',
  sagelightToBG: '#F5F0E6',
  sagemediumToRacingGreen: '#233B2B',
  whiteToSandstone: '#F5F0E6',
  sagedarkToSandstone: '#F5F0E6',
  sagemediumToBlack: '#000000',
  sagedarkToblack: '#000000',

  // Shopping Package collor addiition
  whiteChocolate: '#EDE0D9',
  darkGold: '#8A6C45',
  midGray: '#BBB7AD',
  beaver: '#A1896A',
  darkTeal: '#434341',
  divisor: '#bbb7ad',
  lightShadow: '#d3dae2',

  // colors for Partners Service and Offers
  carouselDot: 'rgba(0,0,0,0.2)',
  carouselActiveDot: 'rgba(0,0,0,0.5)',
  darkToGreen: '#000000',
  coaldarkToBlack: '#000000',
  coallightToBlack: '#000000',
}

export default colors
// Village brand palettes - Keyed against the village codes we should be
// using this w styled system (https://styled-system.com/api#themeget)
// eg: themeGet(`palettes[${villageCode}].primary`) - This could be set via context
// TODO: Add unique values for each village.

export const palettes = {
  tbvsc: {
    // Collection
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  bv: {
    // Bicester
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  fv: {
    // Fidenza
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  iv: {
    // Ingolstadt
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  kv: {
    // Kildare
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  lrv: {
    // La Roca
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  lzv: {
    // Las Rozas
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  lvv: {
    // La Vallee
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  mmv: {
    // Maasmechlen
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  wv: {
    // Wertheim
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
}
