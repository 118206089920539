/* eslint-disable no-unused-expressions */
import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import ChevronIcon from 'components/Icons/ChevronIcon'
import {
  HeaderLanguageSelector,
  HeaderLanguageText,
  HeaderLanguageOptions,
  HeaderLanguageItem,
  HeaderLanguageButton,
  NonClickableHeaderLanguageButton,
  HeaderLanguageSelectorOverlay,
} from './HeaderLanguageSelector.style'
import HeaderLanguageSelectorItem from './HeaderLanguageSelectorItem'

const LanguageSelector = ({
  selectedLanguage,
  languageSelectorOpen,
  closeLanguageSelector,
  languageSelectorHandler,
  closeNav,
  languageList,
  showSkinny,
  showDark,
  navOpen,
  shouldForceSandwichMenu,
  pathname,
  translatedSlugs,
  display,
  forceWhiteLabels,
  villageName,
}) => {
  const propsTheme = useTheme()

  const color = showSkinny || showDark || navOpen ? 'slate' : 'white'

  const displayProp = display ? 'block' : 'none'
  const displayPropLg = display ? 'flex' : 'none'

  // Preparing LanguageSelector Props
  const languageSelectorProps = {
    width: ['50%', null, null],
    height: [null, null, null],
    textAlign: ['center'],
    flexDirection: ['column', null, null],
    justifyContent: ['center', null, null],
    display: [displayPropLg, displayProp, displayProp],
    pt: [null, null, null],
    position: ['relative', null, null],
    bottom: [0],
    left: [0],
    pl: [null, null, null],
    ml: [null, null, null],
    mr: [null, null, null],
    bg: [propsTheme.colors.whiteToGreen, null, null],
    borderTop: ['solid 1px', null, null],
    shouldForceSandwichMenu,
    languageSelectorOpen,
  }
  if (!shouldForceSandwichMenu) {
    languageSelectorProps.width[2] = 'auto'
    languageSelectorProps.height[2] = '100%'
    languageSelectorProps.flexDirection[2] = 'row'
    languageSelectorProps.justifyContent[2] = 'flex-start'
    languageSelectorProps.display[2] = displayProp
    languageSelectorProps.pt[2] = '4px'
    languageSelectorProps.position[2] = 'relative'
    languageSelectorProps.pl[2] = '20px'
    languageSelectorProps.ml[2] = '20px'
    languageSelectorProps.bg[2] = 'transparent'
    languageSelectorProps.borderTop[2] = 'none'
  }

  // Preparing LanguageLink Props
  const languageButtonProps = {
    color: [
      showDark || navOpen ? 'slate' : propsTheme.colors.sagedark,
      null,
      null,
    ],
    fill: color,
    display: ['block', null, null],
  }
  if (languageSelectorOpen) {
    languageButtonProps.display = ['none', null, null]
  }
  if (!shouldForceSandwichMenu) {
    languageButtonProps.color[2] =
      showSkinny || showDark ? 'slate' : propsTheme.colors.backgroundColor
    languageButtonProps.color[2] = forceWhiteLabels
      ? propsTheme.colors.backgroundColor
      : languageButtonProps.color[2]
    languageButtonProps.display[2] = 'block'
  }

  // Preparing LanguageText Props
  const languageTextProps = {
    fontSize: {
      default: '12px',
    },
    textTransform: {
      default: 'uppercase',
    },
    fontFamily: {
      default: 'BrownStd-Regular',
    },
    letterSpacing: {
      default: '1px',
    },
    lineHeight: {
      default: '1',
    },
    fontWeight: {
      default: 'normal',
    },
    color: {
      default: forceWhiteLabels
        ? propsTheme.colors.white
        : propsTheme.colors.alternativeFont,
    },
  }

  // Preparing LanguageOptions Props
  const languageOptionsProps = {
    as: 'ul',
    padding: [null, null, null],
    position: [null, null, null],
    left: [null, null, null],
    top: [null, null, null],
    background: [null, null, null],
    display: ['flex', null, null],
    flexDirection: ['column', null, null],
    justifyContent: ['space-between'],
    height: [null, null, null],
    opacity: [null, null, null],
    paddingTop: [null, null, '30px'],
    languageSelectorOpen,
  }
  if (languageSelectorOpen) {
    languageOptionsProps.padding = ['40px 60px', null, null]
    languageOptionsProps.height = [`${languageList.length * 48}px`, null, null]
    languageOptionsProps.opacity = ['1', '1', '1']
    languageOptionsProps.scaleY = '1'
  }
  if (!shouldForceSandwichMenu) {
    languageOptionsProps.padding[2] = '40px 60px'
    languageOptionsProps.position[2] = 'absolute'
    languageOptionsProps.left[2] = '-30px'
    languageOptionsProps.top[2] = '100%'
    languageOptionsProps.background[2] = propsTheme.colors.whiteToGreen
    languageOptionsProps.height[2] = 'auto'
  }

  // Preparing LanguageItem Props
  const languageItemProps = {
    p: ['15px 0', null, null],
    textAlign: ['center', null, null],
    ml: ['25px', null, null],
  }
  if (languageSelectorOpen) {
    languageItemProps.p = ['0', null, null]
  }
  if (!shouldForceSandwichMenu) {
    languageItemProps.p[2] = '0'
    languageItemProps.textAlign[2] = 'left'
    languageItemProps.ml[2] = '0px'
  }

  // Preparing HeaderLanguageSelectorOverlay Props
  const headerLanguageSelectorOverlayProps = {
    display: [languageSelectorOpen ? 'block' : 'none', null, null],
  }
  if (!shouldForceSandwichMenu) {
    headerLanguageSelectorOverlayProps.display[2] = 'none'
  }

  const languageObj = {}
  languageList.forEach((item) => {
    if (item.locale !== selectedLanguage.locale) {
      languageObj[item.locale] = item
    }
  })

  const orderedLanguageSet = Array.from(
    new Set([
      selectedLanguage,
      ...Object.keys(languageObj).map((key) => languageObj[key]),
    ])
  )

  const visibility = display ? 'block' : 'none'

  const hasLanguagesToSelect = orderedLanguageSet.length > 1

  if (!hasLanguagesToSelect) {
    return (
      <HeaderLanguageSelector display={visibility} {...languageSelectorProps}>
        <HeaderLanguageItem {...languageItemProps}>
          <NonClickableHeaderLanguageButton
            {...languageButtonProps}
            data-element="languages">
            <HeaderLanguageText data-element="languages" {...languageTextProps}>
              {selectedLanguage.label}
            </HeaderLanguageText>
          </NonClickableHeaderLanguageButton>
        </HeaderLanguageItem>
      </HeaderLanguageSelector>
    )
  }

  return (
    <HeaderLanguageSelector display={visibility} {...languageSelectorProps}>
      <HeaderLanguageSelectorOverlay
        {...headerLanguageSelectorOverlayProps}
        onClick={closeLanguageSelector}
      />
      <HeaderLanguageItem {...languageItemProps}>
        <HeaderLanguageButton
          {...languageButtonProps}
          data-element="languages"
          onClick={languageSelectorHandler}>
          <HeaderLanguageText data-element="languages" {...languageTextProps}>
            {selectedLanguage.label}
          </HeaderLanguageText>
          <ChevronIcon
            width="7px"
            height="12px"
            color="currentColor"
            transform={
              languageSelectorOpen
                ? 'rotate(90deg) translateY(-4px)'
                : 'rotate(270deg)'
            }
          />
        </HeaderLanguageButton>
      </HeaderLanguageItem>

      <HeaderLanguageOptions {...languageOptionsProps}>
        {languageList &&
          orderedLanguageSet.map((l, index) => (
            <HeaderLanguageSelectorItem
              key={l.locale}
              languageList={languageList
                .reduce((arr, item) => {
                  Object.keys(translatedSlugs).length
                    ? arr.push(`/${item.locale}/.*`)
                    : arr.push(`/${item.locale}`)
                  return arr
                }, [])
                .join('|')}
              {...{
                language: { ...l, index },
                selectedLanguage,
                showSkinny,
                showDark,
                navOpen,
                shouldForceSandwichMenu,
                closeNav,
                closeLanguageSelector,
                pathname,
                translatedSlugs,
                villageName,
              }}
            />
          ))}
      </HeaderLanguageOptions>
    </HeaderLanguageSelector>
  )
}

LanguageSelector.propTypes = {
  forceWhiteLabels: PropTypes.bool,
  languageSelectorHandler: PropTypes.func.isRequired,
  closeLanguageSelector: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.shape({
    label: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
  }).isRequired,
  languageList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  languageSelectorOpen: PropTypes.bool.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  showSkinny: PropTypes.bool.isRequired,
  showDark: PropTypes.bool.isRequired,
  navOpen: PropTypes.bool.isRequired,
  closeNav: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  translatedSlugs: PropTypes.objectOf(PropTypes.string),
  display: PropTypes.bool.isRequired,
  villageName: PropTypes.string,
}

LanguageSelector.defaultProps = {
  forceWhiteLabels: false,
  translatedSlugs: {},
  villageName: '',
}

export default LanguageSelector
