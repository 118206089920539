import React from 'react'
import PropTypes from 'prop-types'
import { Box as NavLinkListItem } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ChevronIcon } from 'components/Icons'
import { Body14 } from 'components/Typography'
import { bp } from 'styles/breakpoints'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'

// TODO come back to check breakpoints

import {
  NavHeading,
  NavLink,
  NavLinkList,
  NavListItem,
} from './FooterNav.style'

class FooterNavListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  render() {
    const {
      category,
      links,
      locale,
      villageName,
      villageSlug,
      valueRetailPage,
    } = this.props
    const { isOpen } = this.state

    const handleToggle = () => {
      const accordianView = window.innerWidth < Number(bp[0].split('px')[0])
      this.setState({
        isOpen: accordianView ? !isOpen : isOpen,
      })
    }

    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const {
            colors: { fontMainColor, iconColor },
          } = theme

          return (
            <NavListItem
              as="li"
              key={category}
              borderStyle="solid"
              borderWidth={['0 0 1px 0', '0']}
              borderColor="alabaster"
              py={[4]}
              px={[4, 4, 0]}>
              <NavHeading color={fontMainColor} onClick={handleToggle}>
                {category}
                <ChevronIcon
                  color={iconColor}
                  width="12px"
                  display={['block', 'none']}
                  transform={isOpen ? 'rotate(90deg)' : 'rotate(270deg)'}
                />
              </NavHeading>
              <NavLinkList
                as="ul"
                overflow="hidden"
                isOpen={isOpen}
                m={[0]}
                p={[0]}
                pt={[5]}
                style={{ listStyle: 'none' }}>
                {links.map(
                  ({ title, label, link, external, customClassName }) => (
                    <NavLinkListItem as="li" key={label} mb={[4]}>
                      <NavLink
                        onClick={() => {
                          trackGTM(
                            'navigation',
                            'navigation',
                            'footer navigation',
                            label
                          )
                          GA4('footer_navigation_click', {
                            navigation_label: label,
                            village_name: villageName,
                          })

                          if (
                            title === 'SHARED_Comp_Footer_link_Download_app'
                          ) {
                            GA4('app_download', { village_name: villageName })
                          }
                        }}
                        to={
                          !external
                            ? `/${(valueRetailPage && 'value-retail/') ||
                                (villageSlug && `${villageSlug}/`)}${locale &&
                                `${locale}/`}${link && `${link}`}`
                            : link
                        }
                        external={external}
                        showIcon={external}
                        className={customClassName || ''}>
                        <Body14 as="span" lineHeight={['2', '2', '1.5']}>
                          {label}
                        </Body14>
                      </NavLink>
                    </NavLinkListItem>
                  )
                )}
              </NavLinkList>
            </NavListItem>
          )
        }}
      </ThemeContext.Consumer>
    )
  }
}

FooterNavListItem.propTypes = {
  category: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      external: PropTypes.bool,
      customClassName: PropTypes.string,
    })
  ).isRequired,
  locale: PropTypes.string.isRequired,
  villageName: PropTypes.string,
  villageSlug: PropTypes.string,
  valueRetailPage: PropTypes.bool,
}

FooterNavListItem.defaultProps = {
  villageName: '',
  villageSlug: '',
  valueRetailPage: false,
}

export default FooterNavListItem
