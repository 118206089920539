import BrownStdRegularWOFF from 'assets/fonts/brown/BrownStd-Regular.woff'
import BrownStdLightWOFF from 'assets/fonts/brown/BrownStd-Light.woff'
import BrownStdBoldWOFF from 'assets/fonts/brown/BrownStd-Bold.woff'
import SangbleuKingdomLightWOFF from 'assets/fonts/sangbleuKingdom/sangbleukingdom-light-webfont.woff'
import SangbleuKingdomLightItalicWOFF from 'assets/fonts/sangbleuKingdom/sangbleukingdom-lightitalic-webfont.woff'
import AimeBoldWOFF from 'assets/fonts/aime/Aime-Bold.woff2'
import AimeBoldItalicWOFF from 'assets/fonts/aime/Aime-BoldItalic.woff2'
import AimeItalicWOFF from 'assets/fonts/aime/Aime-Italic.woff2'
import AimeRegularWOFF from 'assets/fonts/aime/Aime-Regular.woff2'
import AimeThinWOFF from 'assets/fonts/aime/Aime-Thin.woff2'
import AimeThinItalicWOFF from 'assets/fonts/aime/Aime-ThinItalic.woff2'

export const brown = [
  {
    fontFamily: 'BrownStd-Regular',
    src: [['woff', BrownStdRegularWOFF]],
  },
  {
    fontFamily: 'BrownStd-Light',
    src: [['woff', BrownStdLightWOFF]],
  },
  {
    fontFamily: 'BrownStd-Bold',
    src: [['woff', BrownStdBoldWOFF]],
  },
]

export const aime = [
  {
    fontFamily: 'Aime-Regular',
    src: [['woff', AimeRegularWOFF]],
  },
  {
    fontFamily: 'Aime-Thin',
    src: [['woff', AimeThinWOFF]],
  },
  {
    fontFamily: 'Aime-Bold',
    src: [['woff', AimeBoldWOFF]],
  },
  {
    fontFamily: 'Aime-Italic',
    src: [['woff', AimeItalicWOFF]],
  },
  {
    fontFamily: 'Aime-ThinItalic',
    src: [['woff', AimeThinItalicWOFF]],
  },
  {
    fontFamily: 'Aime-BoldItalic',
    src: [['woff', AimeBoldItalicWOFF]],
  },
]

export const sangBleu = [
  {
    fontFamily: 'SangbleuKindgdom-Light',
    src: [['woff', SangbleuKingdomLightWOFF]],
  },
  {
    fontFamily: 'SangbleuKindgdom-Light-Italic',
    src: [['woff', SangbleuKingdomLightItalicWOFF]],
  },
]

/**
 * Noto Sans with Cyrillic & Cyrillic Extended support.
 */
export const notoSansCyrillic = `
  @font-face {
    font-family: 'BrownStd-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr6DRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  @font-face {
    font-family: 'BrownStd-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr4TRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: 'BrownStd-Light';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr6DRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  @font-face {
    font-family: 'BrownStd-Light';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr4TRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: 'BrownStd-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVadyBx2pqPIif.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  @font-face {
    font-family: 'BrownStd-Bold';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVYNyBx2pqPIif.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
`

// function to create the fontface string
export const fontfaceStr = ({ ...args }) => `
  @font-face {
    font-family: '${args.fontFamily}';
    ${args.src.reduce(
      (acc, file, i) =>
        `${acc}${i ? ',' : ''}url(${file[1]}) format('${file[0]}')`,
      'src: '
    )};
  }
`

// Takes font array (above) and returns the @font-face string
export const fontFaceFromArray = (arr) =>
  arr.reduce((acc, o) => `${acc}${fontfaceStr(o)}`, '')

const fonts = {
  base: [...brown, ...sangBleu, ...aime],
  // 'zh-CN': [...sangBleu, ...someLocaleSpecificFont], // <-  EXAMPLE FOR ALTERNATE LOCALE
}

export default fonts
