import React from 'react'
import PropTypes from 'prop-types'
import trackGTM from 'utils/trackGTM'
import { useTheme } from 'styled-components'

import GA4 from 'utils/GA4'
import {
  HeaderLanguageLink,
  HeaderLanguageLinkActive,
  HeaderLanguageText,
  HeaderLanguageItem,
} from './HeaderLanguageSelector.style'

const HeaderLanguageSelectorItem = ({
  language: { label, locale, index },
  selectedLanguage,
  showSkinny,
  showDark,
  navOpen,
  shouldForceSandwichMenu,
  closeNav,
  closeLanguageSelector,
  pathname,
  languageList,
  translatedSlugs,
  villageName,
}) => {
  const activeLanguage = label === selectedLanguage.label
  const regex = new RegExp(`(${languageList})`, 'i')
  const translatedSlug = translatedSlugs[locale]
    ? `/${translatedSlugs[locale]}`
    : ''
  const url = pathname.replace(regex, `/${locale}${translatedSlug}`)
  const propsTheme = useTheme()

  // Preparing LanguageLink Props
  const languageLinkProps = {
    to: url,
    color: [
      showDark || navOpen ? 'slate' : propsTheme.colors.backgroundColor,
      null,
      null,
    ],
    // z: true, /* produces error Bolean invalid vaue @Fernando - is this needed? */
  }

  if (!shouldForceSandwichMenu) {
    languageLinkProps.color[2] =
      showSkinny || showDark ? 'slate' : propsTheme.colors.backgroundColor
  }

  // Preparing LanguageText Props
  const languageTextProps = {
    fontSize: {
      default: '12px',
    },
    textTransform: {
      default: 'uppercase',
    },
    fontFamily: {
      default: 'BrownStd-Regular, sans-serif',
    },
    letterSpacing: {
      default: '1px',
    },
    lineHeight: {
      default: '1',
      sm: '1',
      md: '1',
      lg: '1',
      xl: '1',
    },
    fontWeight: {
      default: 'normal',
    },
    color: {
      default: propsTheme.colors.alternativeFont,
    },
  }

  // Preparing LanguageItem Props
  const languageItemProps = {
    as: 'li',
    p: ['15px 0'],
    textAlign: 'center',
    display: 'block',
  }
  if (!shouldForceSandwichMenu) {
    // @ts-ignore
    languageItemProps.display = activeLanguage
      ? ['block', 'block', 'none']
      : 'block'
  }

  const content = (
    <HeaderLanguageText {...languageTextProps}>{label}</HeaderLanguageText>
  )

  return (
    <HeaderLanguageItem
      display={activeLanguage ? ['block', 'block', 'none'] : 'block'}
      key={`${locale}_${index}`}
      {...languageItemProps}
      onClick={() => {
        if (activeLanguage) {
          closeLanguageSelector()
        } else {
          closeNav()
        }
        trackGTM(
          'language options',
          'language options',
          'language selected',
          label
        )
        GA4('header_navigation_language_options', { village_name: villageName })
      }}>
      {activeLanguage ? (
        <HeaderLanguageLinkActive {...languageLinkProps}>
          {content}
        </HeaderLanguageLinkActive>
      ) : (
        <HeaderLanguageLink {...languageLinkProps}>
          {content}
        </HeaderLanguageLink>
      )}
    </HeaderLanguageItem>
  )
}

HeaderLanguageSelectorItem.propTypes = {
  language: PropTypes.shape({
    label: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  }).isRequired,
  selectedLanguage: PropTypes.shape({
    label: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
  }).isRequired,
  showSkinny: PropTypes.bool.isRequired,
  showDark: PropTypes.bool.isRequired,
  navOpen: PropTypes.bool.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  closeLanguageSelector: PropTypes.func.isRequired,
  closeNav: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  languageList: PropTypes.string.isRequired,
  translatedSlugs: PropTypes.objectOf(PropTypes.string),
  villageName: PropTypes.string,
}

HeaderLanguageSelectorItem.defaultProps = {
  translatedSlugs: {},
  villageName: '',
}

export default HeaderLanguageSelectorItem
