import styled from 'styled-components'
import { space } from 'styled-system'
import { rem } from 'polished'
import theme from 'styles/theme'

export const Wrapper = styled.div`
  ${space}
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }

  h1 {
    ${(props) => props.theme.textStyles.title70}
    font-size: ${rem(42)};

    ${theme.mediaQueries.large} {
      font-size: ${rem(70)};
    }
  }

  h2 {
    ${(props) => props.theme.textStyles.title60}
    font-size: ${rem(32)};

    ${theme.mediaQueries.large} {
      font-size: ${rem(60)};
    }
  }

  h3 {
    ${(props) => props.theme.textStyles.title42}
    color: ${(props) => props.theme.colors.sagedarkToblack};
    font-size: ${rem(26)};

    ${theme.mediaQueries.large} {
      font-size: ${rem(42)};
    }
  }

  h4 {
    ${(props) => props.theme.textStyles.title32}
    font-size: ${rem(26)};

    ${theme.mediaQueries.large} {
      font-size: ${rem(32)};
    }
  }

  h5 {
    ${(props) => props.theme.textStyles.title26}
    font-size: ${rem(26)};
  }

  h6 {
    ${(props) => props.theme.textStyles.title20}
    font-size: ${rem(20)};
  }

  p,
  ul,
  ol {
    ${(props) => props.theme.textStyles.body16}
    font-size: ${rem(16)};
  }

  li > p {
    font-size: inherit !important;
  }

  a {
    text-decoration: underline;
  }
`

export default Wrapper
