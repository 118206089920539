import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bp } from 'styles/breakpoints'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'
import Banner from 'components/Banner'
import getEmergencyBanner from 'services/bannerService'
import HeaderMasthead from './HeaderMasthead'
import HeaderNav from './HeaderNav'
import HeaderPopup from './HeaderPopup'
import { HeaderElement, HeaderWrapper, HeaderBackground } from './Header.style'

const OFFSET_NUMBER_OF_MENU_ITEMS_FOR_SMALLER_FONTS = 4
const OFFSET_SCROLL_TO_SHOW_SKINNY_MENU = 5
const PAGE_SCROLL_POSITION_TO_ALLOW_MENU_VISIBILITY_TO_CHANGE = 300
const OFFSET_SCROLL_TO_CHANGE_VISIBILITY = 20

class Header extends Component {
  constructor(props) {
    super(props)
    this.direction = 0
    this.previousScrollPosition = 0
    this.positionAtLastChangeOfDirection = 0

    this.state = {
      navOpen: false,
      navVisible: true,
      showSkinny: false,
      showDark: false,
      shouldForceSandwichMenu: false,
      shouldShowSmallerFonts: false,
      languageSelectorOpen: false,
      villageSelectorOpen: false,
      loginOpen: false,
      forceDark: false,
      displayLogin: true,
      displayLanguageSelector: true,
      displayVillageSelector: true,
      headerBackgroundTransparent: true,
      bannerData: {},
    }
    this.navHandler = this.navHandler.bind(this)
    this.languageSelectorHandler = this.languageSelectorHandler.bind(this)
    this.loginHandler = this.loginHandler.bind(this)
    this.villageHandler = this.villageHandler.bind(this)
    this.closeNav = this.closeNav.bind(this)
    this.closeLanguageSelector = this.closeLanguageSelector.bind(this)
  }

  async componentDidMount() {
    this.init()
    window.addEventListener('scroll', this.scrollHandler)
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()

    const { villageSlug, villageServicesEndPoint, hideLanguages } = this.props

    let data
    if (villageSlug && villageServicesEndPoint) {
      data = await getEmergencyBanner(villageSlug, villageServicesEndPoint)
    }

    this.setState({
      forceDark: !document.querySelector('.HeroComponentPresent'),
      showDark: true,
      headerBackgroundTransparent: true,
      bannerData: data || {},
      displayLanguageSelector: !hideLanguages ?? true,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { menuItems } = this.props
    const { languageSelectorOpen, loginOpen, villageSelectorOpen } = this.state

    // If menu items change, do initial checks for character number, etc
    if (JSON.stringify(prevProps.menuItems) !== JSON.stringify(menuItems)) {
      this.init()
      this.scrollHandler()
      this.resizeHandler()
    }

    if (
      prevState.forceDark !== !document.querySelector('.HeroComponentPresent')
    ) {
      // eslint-disable-next-line
      this.setState({
        forceDark: !document.querySelector('.HeroComponentPresent'),
      })
    }

    if (prevState.languageSelectorOpen !== languageSelectorOpen) {
      if (languageSelectorOpen) {
        document.addEventListener('click', () => this.closeLanguageSelector)
      } else {
        document.removeEventListener('click', () => this.closeLanguageSelector)
        // eslint-disable-next-line
        this.setState({
          displayLogin: true,
          displayLanguageSelector: true,
          displayVillageSelector: true,
        })
      }
    }

    if (prevState.loginOpen !== loginOpen) {
      if (loginOpen) {
        document.addEventListener('click', () => this.closeLanguageSelector)
      } else {
        document.removeEventListener('click', () => this.closeLanguageSelector)
        // eslint-disable-next-line
        this.setState({
          displayLogin: true,
          displayLanguageSelector: true,
          displayVillageSelector: true,
        })
      }
    }

    if (prevState.villageSelectorOpen !== villageSelectorOpen) {
      if (villageSelectorOpen) {
        document.addEventListener('click', () => this.closeLanguageSelector)
      } else {
        document.removeEventListener('click', () => this.closeLanguageSelector)
        // eslint-disable-next-line
        this.setState({
          displayLogin: true,
          displayLanguageSelector: true,
          displayVillageSelector: true,
        })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler)
    window.removeEventListener('resize', this.resizeHandler)
    document.querySelector('html').style.overflow = ''
  }

  calculateHeaderSizeBurger = () => {
    if (this.resizeThrottle) return

    this.resizeThrottle = true
    this.setState({ shouldForceSandwichMenu: false }, () => {
      const { masthead, nav } = this

      const windowWidth = Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      )

      if (masthead && nav) {
        const navContentWidth =
          nav.firstChild.firstChild.scrollWidth +
          nav.lastElementChild.offsetWidth +
          126 // Fix for IE 11 // 126px accounts for spacing
        const navWidth = Math.max(nav.offsetWidth, navContentWidth)
        const totalSize = navWidth + masthead.offsetWidth
        this.setState(
          {
            shouldForceSandwichMenu:
              totalSize > parseInt(bp[2], 10) || totalSize > windowWidth,
          },
          () => {
            this.resizeThrottle = false
          }
        )
      }
    })
  }

  scrollHandler = () => {
    this.calculateState()
  }

  resizeHandler = () => {
    this.calculateHeaderSizeBurger()

    const { navOpen, shouldForceSandwichMenu } = this.state

    const windowWidth = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    )

    // Checking if width of page converted to em is
    // higher than desktop breakpoint
    const isDesktop = windowWidth > parseInt(bp[1], 10)

    if (isDesktop && !shouldForceSandwichMenu) {
      // if desktop and not a sandwich menu
      if (navOpen) {
        // only reset navOpen if it is true
        this.setState({
          navOpen: false,
        })
      }
      document.querySelector('html').style.overflow = ''
      this.calculateState()
    }
  }

  init() {
    let shouldShowSmallerFonts = false
    const { menuItems } = this.props

    // If number of menu items higher than offset, make fonts
    // smaller on mobile and tablet
    if (menuItems.length > OFFSET_NUMBER_OF_MENU_ITEMS_FOR_SMALLER_FONTS) {
      shouldShowSmallerFonts = true
    }

    this.setState({
      shouldShowSmallerFonts,
    })
  }

  calculateState() {
    const { showSkinny, navOpen, navVisible } = this.state
    let stateChanged = false
    const newState = {}
    const scrollTop =
      document.documentElement && document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop

    // If the current scroll position is higher than the previous,
    // it means the direction is downwards
    // If the current set direction is not downwards, set it to be downwards,
    // also setting the position at changing of direction
    if (scrollTop > this.previousScrollPosition && this.direction > -1) {
      this.direction = -1
      this.positionAtLastChangeOfDirection = scrollTop
      newState.headerBackgroundTransparent = true
      newState.forceWhiteLabels = true

      // If the current scroll position is lower than the previous,
      // it means the direction is upwards
      // If the current set direction is not upwards, set it to be upwards,
      // also setting the position at changing of direction
    } else if (scrollTop < this.previousScrollPosition && this.direction < 1) {
      this.direction = 1
      this.positionAtLastChangeOfDirection = scrollTop
      newState.headerBackgroundTransparent = false
    }

    if (scrollTop === 0) {
      newState.headerBackgroundTransparent = true
    }

    // If the offset between position at last change of direction and
    // current scroll position is higher than 10
    if (
      Math.abs(scrollTop - this.positionAtLastChangeOfDirection) >
      OFFSET_SCROLL_TO_CHANGE_VISIBILITY
    ) {
      newState.navVisible = !(this.direction < 0)
      stateChanged = true
    }

    // Always show nav if scrolltop position is less than offset
    if (
      scrollTop < PAGE_SCROLL_POSITION_TO_ALLOW_MENU_VISIBILITY_TO_CHANGE &&
      (!navVisible || !newState.navVisible)
    ) {
      newState.navVisible = true
    }

    // Always set nav to be skinny if scrolltop position is more than offset
    if (scrollTop > OFFSET_SCROLL_TO_SHOW_SKINNY_MENU) {
      newState.showSkinny = true
      // Otherwise only set it false if not already set
    } else if (showSkinny) {
      newState.showSkinny = false
    }

    // If Nav is Open, make it always visible if set to not visible
    if (navOpen && (!navVisible || !newState.navVisible))
      newState.navVisible = true

    // Close language selector on scroll
    if (stateChanged) {
      newState.languageSelectorOpen = false
      newState.loginOpen = false
      newState.villageSelectorOpen = false
      newState.showDark = false
    }

    // Set state if any property has been added to newState Object
    if (Object.keys(newState).length) this.setState(newState)

    // Cache current scroll position for next iteration
    this.previousScrollPosition = scrollTop
  }

  navHandler() {
    const { navOpen } = this.state
    const { villageName } = this.props
    if (!navOpen) {
      GA4('header_navigation_open', {
        village_name: villageName,
      })
    } else {
      GA4('header_navigation_close', {
        village_name: villageName,
      })
    }
    document.querySelector('html').style.overflow = navOpen ? '' : 'hidden'
    this.setState({
      navOpen: !navOpen,
      languageSelectorOpen: false,
    })
  }

  closeNav() {
    const { navOpen, villageSelectorOpen } = this.state
    const newState = {
      languageSelectorOpen: false,
    }

    if (navOpen) {
      document.querySelector('html').style.overflow = ''
      newState.navOpen = false
    }

    if (villageSelectorOpen) {
      document.querySelector('html').style.overflow = ''
      newState.villageSelectorOpen = false
    }

    this.setState(newState)
  }

  languageSelectorHandler(event) {
    const { languageSelectorOpen, shouldForceSandwichMenu } = this.state
    const { languageItems, locale, villageName, pageLevel } = this.props

    const selectedLanguage = languageItems.filter(
      (item) => item.locale === locale
    )[0]

    trackGTM(
      'language options',
      'language options',
      'language selected',
      selectedLanguage.label
    )

    GA4('header_navigation_language_selector', {
      village_name:
        pageLevel === 'collection' ? 'The Bicester Collection' : villageName,
    })

    this.setState({
      languageSelectorOpen: !languageSelectorOpen,
      loginOpen: false,
      villageSelectorOpen: false,
      showDark: true,
      displayLogin:
        shouldForceSandwichMenu && !!event
          ? event.target.dataset.element === 'login'
          : true,
      displayLanguageSelector:
        shouldForceSandwichMenu && !!event
          ? event.target.dataset.element === 'languages'
          : true,
      displayVillageSelector:
        shouldForceSandwichMenu && !!event
          ? event.target.dataset.element === 'villages'
          : true,
    })
  }

  loginHandler(event) {
    const { villageName } = this.props
    const { loginOpen, shouldForceSandwichMenu } = this.state

    GA4('header_navigation_account_selector', { village_name: villageName })

    this.setState({
      languageSelectorOpen: false,
      loginOpen: !loginOpen,
      villageSelectorOpen: false,
      showDark: true,
      displayLogin:
        shouldForceSandwichMenu && !!event
          ? event.target.dataset.element === 'login'
          : true,
      displayLanguageSelector:
        shouldForceSandwichMenu && !!event
          ? event.target.dataset.element === 'languages'
          : true,
      displayVillageSelector:
        shouldForceSandwichMenu && !!event
          ? event.target.dataset.element === 'villages'
          : true,
    })
  }

  villageHandler(event) {
    const { villageSelectorOpen, shouldForceSandwichMenu } = this.state

    this.setState({
      languageSelectorOpen: false,
      villageSelectorOpen: !villageSelectorOpen,
      showDark: true,
      displayLogin: !shouldForceSandwichMenu,
      displayLanguageSelector: !shouldForceSandwichMenu,
      displayVillageSelector:
        shouldForceSandwichMenu && !!event
          ? event.target.dataset.element === 'villages'
          : true,
    })
  }

  closeLanguageSelector() {
    const { languageSelectorOpen, loginOpen, villageSelectorOpen } = this.state

    if (languageSelectorOpen) {
      this.setState({
        languageSelectorOpen: false,
      })
    }

    if (loginOpen) {
      this.setState({
        loginOpen: false,
      })
    }

    if (villageSelectorOpen) {
      this.setState({
        villageSelectorOpen: false,
      })
    }
  }

  render() {
    const {
      languageItems,
      signinItems,
      locale,
      logos,
      menuItems,
      notification,
      pageLevel,
      pathname,
      servicesPage,
      translatedServices,
      translatedSlugs,
      valueRetailPage,
      villageSlug,
      villageName,
      membershipPopUp,
      villages,
      theme,
    } = this.props

    const {
      navOpen,
      navVisible,
      shouldForceSandwichMenu,
      shouldShowSmallerFonts,
      showSkinny,
      showDark,
      languageSelectorOpen,
      loginOpen,
      villageSelectorOpen,
      displayLogin,
      displayLanguageSelector,
      displayVillageSelector,
      forceDark,
      headerBackgroundTransparent,
      bannerData,
    } = this.state

    const selectedLanguage = languageItems.filter(
      (item) => item.locale === locale
    )[0]

    // Preparing HeaderElement Props
    const headerElementProps = {
      as: 'header',
      position: 'fixed',
      width: '100%',
      top: '0px',
      height: ['60px', null, showSkinny ? '70px' : '100px'],
      zIndex: 100,
      dir: 'ltr',
      transform: 'translate3d(0px, 0px, 0)',
    }

    if (
      !navVisible &&
      !navOpen &&
      (!languageSelectorOpen || !loginOpen || !villageSelectorOpen)
    ) {
      // Hide navigation
      headerElementProps.transform = 'translate3d(0px, -200px, 0)'
    }

    if (navOpen) {
      headerElementProps.height = ['100%', null, null]
      if (!shouldForceSandwichMenu) {
        headerElementProps.height[2] = 'auto'
      }
    }

    // Preparing HeaderWrapper Props
    const headerWrapperProps = {
      height: [navOpen ? '100vh' : 'auto', null, showSkinny ? '70px' : '100px'],
      width: '100%',
      flexDirection: ['column', null, 'row'],
      margin: '0 auto',
      position: 'relative',
      maxWidth: theme.containterMaxWidth,
    }

    if (shouldForceSandwichMenu) {
      headerWrapperProps.flexDirection[2] = null
    }

    let showDarkOverride = forceDark ? true : showDark
    showDarkOverride = headerBackgroundTransparent ? false : showDarkOverride

    // Preparing HeaderMasthead Props
    const headerMastheadProps = {
      forceWhiteLabels: true,
      showSkinny,
      showDark: showDarkOverride,
      navOpen,
      navHandler: this.navHandler,
      closeNav: this.navHandler,
      shouldForceSandwichMenu,
      languageSelectorOpen,
      logos,
      locale,
      villageSlug,
      villageName,
      valueRetailPage,
      servicesPage,
      translatedServices,
    }

    // Preparing HeaderNav Props
    const headerNavProps = {
      forceWhiteLabels: headerBackgroundTransparent && navOpen === false,
      showSkinny,
      showDark: showDarkOverride,
      navOpen,
      menuItems,
      logos,
      shouldForceSandwichMenu,
      shouldShowSmallerFonts,
      selectedLanguage,
      languageList: languageItems,
      signin: signinItems,
      displayLogin,
      displayLanguageSelector,
      displayVillageSelector,
      languageSelectorOpen,
      loginOpen,
      villageSelectorOpen,
      closeLanguageSelector: this.closeLanguageSelector,
      languageSelectorHandler: this.languageSelectorHandler,
      loginHandler: this.loginHandler,
      navHandler: this.navHandler,
      villageHandler: this.villageHandler,
      closeNav: this.closeNav,
      locale,
      pathname,
      villageSlug,
      villageName,
      valueRetailPage,
      translatedSlugs,
      villages,
      pageLevel,
    }
    // Preparing HeaderNavElement Props
    const headerBackgroundProps = {
      width: ['100%', null, '100%'],
      height: ['60px', null, showSkinny ? '70px' : '100px'],
      position: 'fixed',
      bg: [
        (forceDark && headerBackgroundTransparent === false) ||
        showDark ||
        showSkinny ||
        navOpen ||
        languageSelectorOpen ||
        loginOpen ||
        villageSelectorOpen
          ? theme.colors.whiteToGreen
          : 'transparent',
        null,
        null,
      ],
      top: '0px',
      transform: 'translate3d(0px, 0, 0)',
    }

    if (navOpen) {
      // If not a sandwich menu on desktop
      headerBackgroundProps.height = ['100%', '100%', '100%']
    }

    if (!shouldForceSandwichMenu) {
      headerBackgroundProps.bg[2] =
        (forceDark && headerBackgroundTransparent === false) ||
        showDark ||
        showSkinny ||
        languageSelectorOpen
          ? theme.colors.whiteToGreen
          : 'transparent'
      headerBackgroundProps.height[2] = showSkinny ? '70px' : '100px'
    }
    if (
      !navVisible &&
      !navOpen &&
      (!languageSelectorOpen || !loginOpen || !villageSelectorOpen)
    ) {
      // Hide navigation
      headerBackgroundProps.transform = 'translate3d(0px, -100px, 0)'
    }

    const headerPopupProps = {
      notification,
      urlProps: {
        nodeLocale: locale,
        pageLevel,
        translatedServices,
        translatedSlugs,
      },
      villageSlug,
      membershipPopUp,
    }

    if (headerBackgroundTransparent && navOpen === false) {
      headerBackgroundProps.bg[2] = 'transparent'
    }

    return (
      <HeaderElement dir="ltr" {...headerElementProps}>
        <HeaderBackground {...headerBackgroundProps} />
        <HeaderWrapper {...headerWrapperProps}>
          <HeaderMasthead
            ref={(r) => {
              this.masthead = r
            }}
            {...headerMastheadProps}
          />
          <HeaderNav
            ref={(r) => {
              this.nav = r
            }}
            {...headerNavProps}
          />
        </HeaderWrapper>
        <HeaderPopup {...headerPopupProps} navOpen={navOpen} />
        {bannerData &&
          bannerData?.message &&
          bannerData?.message?.length > 0 && (
            <Banner
              backgroundColor={bannerData.backgroundColor}
              textColor={bannerData.textColor}
              message={bannerData.message}
              transparent={headerBackgroundTransparent}
            />
          )}
      </HeaderElement>
    )
  }
}

Header.propTypes = {
  languageItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  signinItems: PropTypes.objectOf(PropTypes.string),
  locale: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  notification: PropTypes.shape({
    bodyCopy: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
    cookieHash: PropTypes.string,
    cookieTimeout: PropTypes.number,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    dismiss: PropTypes.string,
    headline: PropTypes.string,
  }),
  pageLevel: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  servicesPage: PropTypes.bool,
  translatedServices: PropTypes.objectOf(PropTypes.string),
  translatedSlugs: PropTypes.objectOf(PropTypes.string),
  valueRetailPage: PropTypes.bool,
  villageSlug: PropTypes.string,
  villageName: PropTypes.string,
  villageServicesEndPoint: PropTypes.string,
  membershipPopUp: PropTypes.shape({
    bodyCopy: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
    secondaryCopy: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
    legalCopy: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
    condensedVersion: PropTypes.bool,
    type: PropTypes.string,
    headline: PropTypes.string,
    cookieTimeout: PropTypes.number,
    timer: PropTypes.number,
  }),
  villages: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  hideLanguages: PropTypes.bool,
  theme: PropTypes.objectOf(PropTypes.string),
}

Header.defaultProps = {
  signinItems: {},
  notification: null,
  pageLevel: '',
  servicesPage: false,
  translatedServices: null,
  translatedSlugs: {},
  valueRetailPage: false,
  villageSlug: '',
  villageName: '',
  membershipPopUp: {},
  villageServicesEndPoint: '',
  villages: null,
  hideLanguages: false,
  theme: null,
}

export default Header
