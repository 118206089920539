import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'
import HeaderLogin from '../HeaderLogin'
import HeaderMenu from '../HeaderMenu'
import { HeaderNavElement, Wrapper } from './HeaderNav.style'

import HeaderLanguageSelector from '../HeaderLanguageSelector'

const HeaderNav = ({
  forceWhiteLabels,
  menuItems,
  logos,
  villageSlug,
  villageName,
  valueRetailPage,
  navOpen,
  navHandler,
  closeNav,
  shouldForceSandwichMenu,
  shouldShowSmallerFonts,
  showSkinny,
  showDark,
  selectedLanguage,
  languageList,
  signin,
  displayLogin,
  displayLanguageSelector,
  displayVillageSelector,
  languageSelectorOpen,
  villageSelectorOpen,
  loginOpen,
  languageSelectorHandler,
  loginHandler,
  villageHandler,
  closeLanguageSelector,
  locale,
  pathname,
  innerRef,
  translatedSlugs,
  villages,
  pageLevel,
}) => {
  const propsTheme = useTheme()
  // Preparing HeaderNavElement Props
  const headerNavElementProps = {
    as: 'nav',
    m: [0],
    p: ['60px 0 60px', null, null],
    position: ['absolute', null, null],
    display: navOpen ? ['block', null, 'block'] : ['none', null, 'none'],
    width: ['100%', null, '100%'],
    height: ['100vh', null, null],
    flexDirection: ['column', null, 'row'],
    alignItems: ['center'],
    justifyContent: ['center', null, 'flex-end'],
    flex: '1 1 auto',
    style: { listStyle: 'none' },
  }

  const wrapperProps = {
    position: 'fixed',
    width: '100%',
  }

  if (!shouldForceSandwichMenu) {
    // If not a sandwich menu on desktop
    // @ts-ignore
    headerNavElementProps.p[2] = 3
    headerNavElementProps.display[2] = 'flex'
    headerNavElementProps.width[2] = 'auto'
    headerNavElementProps.height[2] = 'auto'
    headerNavElementProps.position[2] = 'relative'

    wrapperProps.position = 'relative'
    wrapperProps.width = 'auto'
  }

  // Preparing HeaderMenu Props
  const headerMenuProps = {
    forceWhiteLabels,
    logos,
    showSkinny,
    showDark,
    navOpen,
    menuItems,
    shouldForceSandwichMenu,
    shouldShowSmallerFonts,
    locale,
    navHandler,
    closeNav,
    villageSlug,
    villageName,
    valueRetailPage,
    villages,
    displayVillageSelector,
    villageSelectorOpen,
    villageHandler,
    closeVillageSelector: closeLanguageSelector,
    pageLevel,
  }

  // Preparing HeaderLanguageSelector Props
  const headerLanguageSelectorProps = {
    forceWhiteLabels,
    selectedLanguage,
    languageSelectorOpen,
    languageSelectorHandler,
    closeLanguageSelector,
    closeNav,
    languageList,
    showSkinny,
    showDark,
    navOpen,
    navHandler,
    shouldForceSandwichMenu,
    pathname,
    translatedSlugs,
    display: displayLanguageSelector,
    villageName,
  }

  // Preparing HeaderLogin Props
  const headerLoginProps = {
    forceWhiteLabels,
    selectedLanguage,
    languageSelectorOpen: loginOpen,
    languageSelectorHandler: loginHandler,
    closeLanguageSelector,
    closeNav,
    languageList,
    signin,
    display: displayLogin,
    showSkinny,
    showDark,
    navOpen,
    navHandler,
    shouldForceSandwichMenu,
    pathname,
    translatedSlugs,
    villageName,
    propsTheme,
  }

  return (
    <HeaderNavElement ref={innerRef} {...headerNavElementProps}>
      <HeaderMenu {...headerMenuProps} />
      <Wrapper {...wrapperProps}>
        {(navOpen || !shouldForceSandwichMenu) && signin ? (
          <HeaderLogin {...headerLoginProps} />
        ) : null}
        {navOpen || !shouldForceSandwichMenu ? (
          <HeaderLanguageSelector {...headerLanguageSelectorProps} />
        ) : null}
      </Wrapper>
    </HeaderNavElement>
  )
}

HeaderNav.propTypes = {
  forceWhiteLabels: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  logos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  navOpen: PropTypes.bool.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  shouldShowSmallerFonts: PropTypes.bool.isRequired,
  showSkinny: PropTypes.bool.isRequired,
  showDark: PropTypes.bool.isRequired,
  selectedLanguage: PropTypes.shape({
    label: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
  }).isRequired,
  languageList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  signin: PropTypes.objectOf(PropTypes.string),
  displayLogin: PropTypes.bool.isRequired,
  displayLanguageSelector: PropTypes.bool.isRequired,
  displayVillageSelector: PropTypes.bool.isRequired,
  languageSelectorOpen: PropTypes.bool.isRequired,
  loginOpen: PropTypes.bool.isRequired,
  villageSelectorOpen: PropTypes.bool.isRequired,
  languageSelectorHandler: PropTypes.func.isRequired,
  loginHandler: PropTypes.func.isRequired,
  villageHandler: PropTypes.func.isRequired,
  closeLanguageSelector: PropTypes.func.isRequired,
  navHandler: PropTypes.func.isRequired,
  closeNav: PropTypes.func.isRequired,
  villageSlug: PropTypes.string,
  villageName: PropTypes.string,
  valueRetailPage: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  innerRef: PropTypes.func,
  translatedSlugs: PropTypes.objectOf(PropTypes.string),
  villages: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  pageLevel: PropTypes.string,
}

HeaderNav.defaultProps = {
  forceWhiteLabels: false,
  logos: [],
  signin: {},
  villageSlug: '',
  villageName: '',
  valueRetailPage: false,
  innerRef: () => {},
  translatedSlugs: {},
  villages: null,
  pageLevel: '',
}

export default React.forwardRef((props, ref) => (
  // @ts-ignore
  <HeaderNav {...props} innerRef={ref} />
))
