import { rem } from 'polished'

const aime = {
  fontWeight: 'normal',
  fontFamily: 'Aime-Regular, serif',
  letterSpacing: '0px',
}

const aimeThin = {
  fontWeight: 'normal',
  fontFamily: 'Aime-Thin, serif',
  lineHeight: ['0.9'],
  letterSpacing: '0px',
}
const aimeThinItalic = {
  fontWeight: 'normal',
  fontFamily: 'Aime-ThinItalic, serif',
  lineHeight: ['1.2'],
  letterSpacing: '0px',
}

const fontPrimary = aime.fontFamily
const fontPrimaryThin = aimeThin.fontFamily
const fontPrimaryThinItalic = aimeThinItalic.fontFamily
const aimeRegularFont = aime.fontFamily

const brownStd = {
  fontFamily: 'BrownStd-Regular, sans-serif',
  lineHeight: ['1.2'],
  letterSpacing: '0px',
}

const brownStdLight = {
  fontFamily: 'BrownStd-Light, sans-serif',
  lineHeight: ['1.4'],
  letterSpacing: '0px',
}

const fontMainBody = brownStdLight.fontFamily

const title100 = {
  fontSize: {
    default: rem(100),
  },
  fontFamily: 'Aime-Italic, serif',
  lineHeight: ['1.25'],
  letterSpacing: '0px',
}

const title70 = {
  fontSize: {
    default: rem(42),
    lg: rem(70),
  },
  ...aime,
  letterSpacing: '-3.5px',
}

const title60 = {
  fontSize: {
    default: rem(32),
    lg: rem(60),
  },
  ...aime,
  lineHeight: ['1.13'],
}

const title42 = {
  fontSize: {
    default: rem(26),
    lg: rem(42),
  },
  ...aime,
  lineHeight: ['1.14'],
}

const title32 = {
  fontSize: {
    default: rem(26),
    lg: rem(32),
  },
  ...aimeThin,
  lineHeight: ['1.25'],
}

const title26 = {
  fontSize: {
    default: rem(26),
  },
  ...aimeThin,
  lineHeight: ['1.4'],
}

const title20 = {
  fontSize: {
    default: rem(20),
  },
  ...aimeThinItalic,
}

const title16 = {
  fontSize: {
    default: rem(16),
  },
  ...aime,
  lineHeight: ['1.25'],
}

const title20Italics = {
  ...title20,
  fontFamily: aimeThinItalic,
}

const body20 = {
  fontSize: {
    default: rem(18),
    lg: rem(20),
  },
  ...brownStd,
  lineHeight: ['1.6'],
}

const body18 = {
  fontSize: {
    default: rem(16),
    lg: rem(18),
  },
  ...brownStdLight,
  lineHeight: ['1.6'],
}

const body16 = {
  fontSize: {
    default: rem(14),
    lg: rem(16),
  },
  ...brownStdLight,
  lineHeight: ['1.5'],
}

const body14 = {
  fontSize: {
    default: rem(14),
  },
  ...brownStd,
  lineHeight: ['1.42'],
}

const body12 = {
  fontSize: {
    default: rem(12),
  },
  ...brownStd,
  lineHeight: ['1.5'],
}

const body12Uppercase = {
  ...body12,
  textTransform: 'uppercase',
}
const body12UppercaseCondensed = {
  ...body12,
  textTransform: 'uppercase',
  lineHeight: ['1.2'],
}

const body10Uppercase = {
  fontSize: {
    default: rem(10),
  },
  textTransform: 'uppercase',
  ...brownStd,
  lineHeight: ['1.2'],
}

const body10UppercaseCondensed = {
  fontSize: {
    default: rem(10),
  },
  textTransform: 'uppercase',
  ...brownStd,
}

const body8Uppercase = {
  fontSize: {
    default: rem(8),
  },
  textTransform: 'uppercase',
  ...brownStd,
}

const label = {
  ...body8Uppercase,
  padding: '1px 4px',
  display: 'inline-block',
}

export default {
  title70,
  title60,
  title42,
  title32,
  title26,
  title20,
  title20Italics,
  title16,
  body20,
  body18,
  body16,
  body14,
  body12,
  body12Uppercase,
  body12UppercaseCondensed,
  body10Uppercase,
  body10UppercaseCondensed,
  body8Uppercase,
  title100,
  label,
  fontPrimary,
  fontPrimaryThin,
  fontPrimaryThinItalic,
  brownStdLight,
  brownStd,
  fontMainBody,
  aimeRegularFont,
}
