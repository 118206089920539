import React from 'react'
import styled, { css } from 'styled-components'
import {
  color,
  height,
  fontFamily,
  fontWeight,
  position,
  top,
  right,
  bottom,
  left,
  display,
  padding,
  background,
  margin,
  textAlign,
  opacity,
  letterSpacing,
  lineHeight,
  fontSize,
  width,
  alignItems,
  paddingTop,
} from 'styled-system'
import { Flex, Box } from 'rebass'

import Link from 'components/Link'
import { CtaLabel } from 'components/Typography'
import { appearingAnimation } from 'styles/animation'
import { fill, textTransform as textTransformStyle } from 'styles/sharedStyle'
import theme from 'styles/theme'

const diamondStyle = css`
  display: inline-block;
  position: relative;
  overflow: visible;
  ::after {
    content: '♦︎';
    display: inline-block;
    font-size: 5px;
    transition: opacity 0.4s ease-in-out;
    right: 0;
    top: 50%;
    opacity: 1;
    position: absolute;
    margin-right: -15px;
    margin-top: -6px;
  }
`

export const HeaderVillageSelectorOverlay = styled(Flex)`
  ${display}
  position: absolute;
  left: -500%;
  width: 1000%;
  height: 1000%;
  top: calc(-1000% - 1px);
  background: rgba(255, 255, 255, 0.9);
  z-index: 3;
`

export const HeaderVillageSelector = styled(Flex)`
  ${top}
  ${left}
  ${right}
  ${bottom}
  ${position}
  ${height}
  ${display}
  ${padding}
  ${fontFamily}
  ${letterSpacing}
  ${textTransformStyle}
  ${fontSize}
  ${lineHeight}
  z-index: 6;
  justify-content: flex-end;

  transition: height .3s ease-in-out;
  opacity: 0;
  bottom: 0px;
  animation: ${appearingAnimation('50px')} 0.4s ease-out;
  animation-direction: normal;
  animation-delay: 0.4s;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  white-space: nowrap;

  ${(props) =>
    !props.shouldForceSandwichMenu
      ? css`
          @media only screen and (max-width: ${theme.breakpoints[1]}) {
            max-height: auto;
            overflow: visible;
            ul {
              transition: height 0.4s ease-in-out;
              will-change: height;
            }

            ${!props.villageSelectorOpen &&
              `
              > ul {
                height: 0;
              }
            `}

            :before {
              content: '';
              position: absolute;
              left: -500%;
              width: 1000%;
              height: 1px;
              top: -1px;
              background: ${theme.colors.alto};
            }
          }
        `
      : css`
          overflow: visible;
          ul {
            transition: height 0.4s ease-in-out;
            will-change: height;
          }

          ${!props.villageSelectorOpen &&
            `
            > ul {
              height: 0;
            }
          `}
        `}
`

export const HeaderVillageOptions = styled(Flex)`
  z-index: 4;
  overflow: visible;
  transform-origin: 50% 0;
  transform: scaleY(0);
  transition: transform 0.4s ease-in;
  transition-delay: 0.4s;
  box-sizing: content-box;
  list-style: none;
  padding: 0;
  ${paddingTop}
  ${position}
  ${left}
  ${background}
  ${opacity}
  ${height}
  ${width}
  ${top}
  display: flex;
  justify-content: flex-end;
  ${(props) =>
    props.villageSelectorOpen &&
    `transition-delay: 0s;
    transform: scaleY(${props.scaleY});
  `}
}

  span {
    opacity: 0;
    transition: opacity 0.4s ease-in;
    transition-delay: 0s;

    ${(props) =>
      props.villageSelectorOpen &&
      `opacity: 1;
      transition-delay: 0.4s;
    `}
  }x
`

export const HeaderVillageOptionsContainer = styled.div`
  ${background}
  padding: 0;

  ${(props) =>
    props.shouldForceSandwichMenu &&
    `border-top: 1px solid ${theme.colors.alto};
  `}
`

export const HeaderVillageLink = styled(
  ({ allowDiamond, textTransform, ...rest }) => <Link {...rest} />
)`
  display: block;
  border: 0;
  cursor: pointer;
  ${textTransformStyle}
  ${fontFamily}
  ${fontWeight}
  ${color}
  ${margin}

  * {
    ${fill};
  }
`

export const HeaderVillageLinkActive = styled(
  ({ allowDiamond, textTransform, ...rest }) => <Link {...rest} />
)`
  display: block;
  border: 0;
  cursor: pointer;
  ${textTransformStyle}
  ${fontFamily}
  ${fontWeight}
  ${color}
  ${margin}

  ${(props) => props.allowDiamond && diamondStyle};
`

export const HeaderVillageButton = styled.div`
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  
  span {
    margin-right: 10px;
  }

  span:first-child {
    display: inline-block;
  }

  span:nth-child(2) {
    position: relative;
    top: 3px;
  }

  ${textTransformStyle}
  ${fontFamily}
  ${fontWeight}
  ${color}
  ${margin}
  ${display}
`

export const NonClickableHeaderVillageButton = styled(HeaderVillageButton)`
  cursor: default;
`

export const HeaderVillageItem = styled(Box)`
  ${display}
  ${alignItems}
  ${textAlign}
  ${textTransformStyle}
  ${fontFamily}
  ${fontWeight}
  ${lineHeight}
  ${fontSize}
  ${color}
  ${letterSpacing}
`

export const HeaderVillageText = styled(({ allowDiamond, ...rest }) => (
  <CtaLabel as="span" {...rest} />
))`
  ${(props) => (props.allowDiamond ? diamondStyle : '')};

  @media only screen and (min-width: ${theme.breakpoints[1]}) {
    ::after {
      opacity: 0;
    }
    :hover::after {
      opacity: 1;
    }
  }
`

export const HeaderVillageCityText = styled(({ allowDiamond, ...rest }) => (
  <CtaLabel as="span" {...rest} />
))`
  ${(props) => (props.allowDiamond ? diamondStyle : '')};

  font-size: 8px;

  @media only screen and (min-width: ${theme.breakpoints[1]}) {
    ::after {
      opacity: 0;
    }
    :hover::after {
      opacity: 1;
    }
  }
`

export const HeaderVillageStatusText = styled(({ allowDiamond, ...rest }) => (
  <CtaLabel as="span" {...rest} />
))`
  ${(props) => (props.allowDiamond ? diamondStyle : '')};

  font-size: 9px;
  padding-top: 5px;

  @media only screen and (min-width: ${theme.breakpoints[1]}) {
    ::after {
      opacity: 0;
    }
    :hover::after {
      opacity: 1;
    }
  }
`
