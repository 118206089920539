import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'rebass'
import { useTheme } from 'styled-components'

import { Body14 } from 'components/Typography'
import Link from 'components/Link'
import { LogoWrapper } from '../Footer.style'

const FooterCopyright = ({ copyright, trademarkCopy, logo }) => {
  const year = new Date().getFullYear()
  const {
    colors: { coallightToBlack },
  } = useTheme()
  const color = 'sagedark'
  return (
    <Flex
      flexDirection={['column', null, 'row']}
      flexWrap={['wrap', 'no-wrap']}
      justifyContent={['flex-start', 'center', 'flex-start']}
      alignItems={[null, 'center']}
      as="section"
      px={[4, null, 0]}
      width={['100%', null, 'auto']}
      py={7}>
      <Link to="https://www.thebicestercollection.com">
        <LogoWrapper
          dangerouslySetInnerHTML={{ __html: logo }}
          mb={[1, null, 0]}
          width="187px"
          fill={color}
        />
      </Link>

      <Box width={[1, 'auto']}>
        <Body14
          width={1}
          fontSize={2}
          color={coallightToBlack}
          mb={[3, 0]}
          letterSpacing="normal">
          {trademarkCopy}
        </Body14>
        <Body14
          width={1}
          fontSize={2}
          lineHeight="1.8"
          color={coallightToBlack}
          mb={0}
          letterSpacing="normal">
          {copyright} {year}
        </Body14>
      </Box>
    </Flex>
  )
}

FooterCopyright.propTypes = {
  trademarkCopy: PropTypes.string.isRequired,
  copyright: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  // locale: PropTypes.string.isRequired,
}

export default FooterCopyright
