const getBGColor = () => ({ theme }) => theme.colors.backgroundColor

const getBtnMainColor = () => ({ theme }) => theme.colors.btnMainColor
const getBtnBGColor = () => ({ theme }) => theme.colors.primaryBGBtn

const getPRMColor = () => ({ theme }) => theme.colors.primary

const getSpacing = (spacing) => ({ theme }) => theme.space[spacing]

const getMediaQuerie = (querie) => ({ theme }) => theme.mediaQueries[querie]

const getMainFont = () => ({ theme }) => theme.textStyles.fontPrimary

const getMainFontThin = () => ({ theme }) => theme.textStyles.fontPrimaryThin

const getMainFontThinItalic = () => ({ theme }) =>
  theme.textStyles.fontPrimaryThinItalic

export {
  getBGColor,
  getSpacing,
  getMediaQuerie,
  getBtnMainColor,
  getPRMColor,
  getBtnBGColor,
  getMainFont,
  getMainFontThin,
  getMainFontThinItalic,
}
