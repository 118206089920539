import React from 'react'

import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'

import GA4 from 'utils/GA4'
import SandwichIcon from 'components/Icons/SandwichIcon'
import CloseIcon from 'components/Icons/CloseIcon'
import { Masthead, Logo, SandwichButton, Link } from './HeaderMasthead.style'

const HeaderMasthead = (props) => {
  const {
    logos,
    navHandler,
    navOpen,
    languageSelectorOpen,
    showSkinny,
    showDark,
    shouldForceSandwichMenu,
    locale,
    closeNav,
    innerRef,
    villageSlug,
    villageName,
    valueRetailPage,
    servicesPage,
    translatedServices,
    forceWhiteLabels,
  } = props
  const propsTheme = useTheme()

  let color =
    showSkinny || showDark || navOpen
      ? 'coaldark'
      : propsTheme.colors.backgroundColor
  if (typeof window !== 'undefined') {
    color =
      forceWhiteLabels && window.innerWidth > 900
        ? propsTheme.colors.backgroundColor
        : color
  }
  // Preparing Masthead Props
  const mastheadProps = {
    p: [3],
    zIndex: 1,
    flexDirection: ['row'],
    position: ['absolute'],
    top: '0',
    justifyContent: ['flex-start', null, null, 'flex-start'],
    height: ['60px', null, showSkinny ? '70px' : '100px'],
    pt: ['9px', null, showSkinny ? '14px' : '29px'],
    backgroundColor: [null, null, null],
  }

  // Preparing SandwichButton Props
  const sandwichButtonProps = {
    navOpen,
    bg: 'transparent',
    onClick: navHandler,
    position: ['absolute'],
    right: [0, null, null],
    display: [
      'inline-block',
      null,
      shouldForceSandwichMenu ? 'inline-block' : 'none',
    ],
    top: ['5px', null, showSkinny ? '10px' : '25px'],
  }

  const translatedService = translatedServices && translatedServices[locale]

  const handleLogoClick = () => {
    if (navOpen) {
      closeNav()
    }
    GA4('header_logo_click', {
      village_name: villageName,
    })
  }

  return (
    <Masthead ref={innerRef} {...mastheadProps}>
      {logos?.length &&
        logos.length > 0 &&
        logos.map((logoObj) => {
          const logo =
            logoObj.code && logoObj.code.code ? logoObj.code.code : ''

          // DX-2157 to avoid SVG class name clash we change the class names of the header logo
          let formattedLogo = logo || ''

          for (let i = 0; i <= 5; i += 1) {
            const oldClassDeclare = new RegExp(`\\.st${i}(\\s)*{`, 'gi')
            formattedLogo = formattedLogo.replaceAll(
              oldClassDeclare,
              `.header${i}{`
            )
            const oldClass = `class="st${i}"`
            formattedLogo = formattedLogo.replaceAll(
              oldClass,
              `class="header${i}"`
            )
          }

          // Preparing Masthead Props
          const logoProps = {
            display: ['block', null, null],
            dangerouslySetInnerHTML: { __html: formattedLogo || logo },
          }

          if (navOpen) {
            if (languageSelectorOpen) {
              logoProps.opacity = ['0.1', null, null]
              if (!shouldForceSandwichMenu) {
                logoProps.opacity[2] = ['1']
              }
            }
          }

          return (
            <Link
              logoAmount={logos.length}
              key={logoObj.id}
              to={`/${(valueRetailPage && 'value-retail/') ||
                (villageSlug && `${villageSlug}/`)}${locale && `${locale}`}${
                servicesPage && !valueRetailPage
                  ? `/${translatedService}` || `/bookings`
                  : ''
              }`}
              onClick={() => handleLogoClick()}>
              <Logo {...logoProps} />
            </Link>
          )
        })}
      <SandwichButton {...sandwichButtonProps} fill={color}>
        {navOpen ? (
          <CloseIcon color={propsTheme.colors.secondaryFont} width={[20]} />
        ) : (
          <SandwichIcon color={propsTheme.colors.secondaryFont} width={[20]} />
        )}
      </SandwichButton>
    </Masthead>
  )
}

HeaderMasthead.propTypes = {
  forceWhiteLabels: PropTypes.bool,
  logos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  navHandler: PropTypes.func.isRequired,
  navOpen: PropTypes.bool.isRequired,
  showSkinny: PropTypes.bool.isRequired,
  showDark: PropTypes.bool.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  languageSelectorOpen: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  closeNav: PropTypes.func.isRequired,
  villageSlug: PropTypes.string,
  villageName: PropTypes.string,
  innerRef: PropTypes.func,
  valueRetailPage: PropTypes.bool,
  servicesPage: PropTypes.bool,
  translatedServices: PropTypes.objectOf(PropTypes.string),
}

HeaderMasthead.defaultProps = {
  forceWhiteLabels: false,
  logos: [],
  villageSlug: '',
  villageName: '',
  innerRef: () => {},
  valueRetailPage: false,
  servicesPage: false,
  translatedServices: null,
}

export default React.forwardRef((props, ref) => (
  // @ts-ignore
  <HeaderMasthead {...props} innerRef={ref} />
))
