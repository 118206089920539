import React from 'react'
import PropTypes from 'prop-types'
import { rem } from 'polished'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'
import { useTheme } from 'styled-components'
import HeaderVillageSelector from '../HeaderVillageSelector'
import {
  HeaderMenuLink,
  HeaderMenuLinkActive,
  HeaderMenuItem,
  HeaderMenuText,
} from './HeaderMenuItem.style'

export const MenuItem = ({
  item: {
    label,
    link,
    active,
    index,
    // eslint-disable-next-line react/prop-types
    external,
  },
  forceWhiteLabels,
  showSkinny,
  showDark,
  navOpen,
  shouldForceSandwichMenu,
  shouldShowSmallerFonts,
  closeNav,
  villageSlug,
  villageName,
  valueRetailPage,
  locale,
  villages,
  navHandler,
  displayVillageSelector,
  villageSelectorOpen,
  villageHandler,
  closeVillageSelector,
  pageLevel,
}) => {
  const {
    textStyles: { fontPrimaryThin },
    colors: themeColors,
  } = useTheme()

  // Preparing HeaderMenuItem Props
  const headerMenuItemProps = {
    as: 'li',
    key: `${label}${locale}`,
    ml: [null, null, 30],
    delay: `.1s`,
    duration: `${0.5 + index * 0.1}s`,
  }
  if (shouldForceSandwichMenu) {
    // If a sandwich menu on desktop
    headerMenuItemProps.ml[2] = null
  }

  // Preparing HeaderMenuLink Props
  const headerMenuLinkProps = {
    to: !external
      ? `/${(valueRetailPage && 'value-retail/') ||
          (villageSlug && `${villageSlug}/`)}${locale && `${locale}/`}${link &&
          `${link}`}`
      : link,
    color: [
      showDark || navOpen ? themeColors.alternativeFont : 'white',
      null,
      null,
    ],
    external,
  }
  if (!shouldForceSandwichMenu) {
    headerMenuLinkProps.color[2] =
      showSkinny || showDark ? themeColors.alternativeFont : 'white'
    headerMenuLinkProps.color[2] = forceWhiteLabels
      ? 'white'
      : headerMenuLinkProps.color[2]

    headerMenuLinkProps.allowDiamond = 'true' // Styled Components needs this to be string
  }

  // Preparing HeaderMenuText Props
  const mobileFontSize = shouldShowSmallerFonts ? rem(32) : rem(42)
  const headerMenuTextProps = {
    fontSize: {
      default: mobileFontSize,
    },
    textTransform: {
      default: null,
      sm: null,
      md: null,
      lg: null,
      xl: null,
    },
    fontFamily: {
      default: fontPrimaryThin,
    },
    letterSpacing: {
      default: '-1.2px',
    },
    lineHeight: {
      default: '2',
    },
    fontWeight: {
      default: 'normal',
    },
    color: {
      default: forceWhiteLabels
        ? themeColors.white
        : themeColors.alternativeFont,
    },
  }
  if (!shouldForceSandwichMenu) {
    // If not a sandwich menu on desktop
    headerMenuTextProps.textTransform = {
      ...headerMenuTextProps.textTransform,
      md: 'uppercase',
      lg: 'uppercase',
      xl: 'uppercase',
    }
    headerMenuTextProps.fontSize = {
      ...headerMenuTextProps.fontSize,
      md: '12px',
      lg: '12px',
      xl: '12px',
    }
    headerMenuTextProps.lineHeight = {
      ...headerMenuTextProps.lineHeight,
      md: '1',
      lg: '1',
      xl: '1',
    }
    headerMenuTextProps.fontFamily = {
      ...headerMenuTextProps.fontFamily,
    }
    headerMenuTextProps.letterSpacing = {
      ...headerMenuTextProps.letterSpacing,
      md: '1px',
      lg: '1px',
      xl: '1px',
    }
  }

  // Preparing HeaderLanguageSelector Props
  const headerVillageSelectorProps = {
    label,
    forceWhiteLabels,
    villageSelectorOpen,
    villageHandler,
    closeVillageSelector,
    closeNav,
    shouldShowSmallerFonts,
    villages,
    showSkinny,
    showDark,
    navOpen,
    navHandler,
    shouldForceSandwichMenu,
    display: displayVillageSelector,
  }

  const trackMenuLink = () => {
    trackGTM('navigation', 'navigation', 'header navigation', label)
    GA4('header_navigation_click', {
      navigation_label: label,
      village_name:
        pageLevel === 'collection' ? 'The Bicester Collection' : villageName,
    })
  }

  const closeNavAndTrackMenuLink = () => {
    closeNav()
    trackMenuLink()
  }

  // Render villages dropdown if there is now link
  if (!link) {
    return <HeaderVillageSelector {...headerVillageSelectorProps} />
  }

  return (
    <HeaderMenuItem {...headerMenuItemProps}>
      {!active ? (
        <HeaderMenuLink
          {...headerMenuLinkProps}
          onClick={navOpen ? closeNavAndTrackMenuLink : trackMenuLink}>
          <HeaderMenuText {...headerMenuTextProps}>{label}</HeaderMenuText>
        </HeaderMenuLink>
      ) : (
        <HeaderMenuLinkActive {...headerMenuLinkProps}>
          <HeaderMenuText {...headerMenuTextProps}>{label}</HeaderMenuText>
        </HeaderMenuLinkActive>
      )}
    </HeaderMenuItem>
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    active: PropTypes.bool,
    index: PropTypes.number.isRequired,
  }),
  navOpen: PropTypes.bool.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  shouldShowSmallerFonts: PropTypes.bool.isRequired,
  showSkinny: PropTypes.bool.isRequired,
  showDark: PropTypes.bool.isRequired,
  closeNav: PropTypes.func.isRequired,
  villageSlug: PropTypes.string,
  villageName: PropTypes.string,
  valueRetailPage: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  forceWhiteLabels: PropTypes.bool,
  villages: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  displayVillageSelector: PropTypes.bool,
  villageSelectorOpen: PropTypes.bool,
  villageHandler: PropTypes.func,
  closeVillageSelector: PropTypes.func,
  navHandler: PropTypes.func,
  pageLevel: PropTypes.string,
}

MenuItem.defaultProps = {
  forceWhiteLabels: false,
  item: {
    active: false,
  },
  villageSlug: '',
  villageName: '',
  valueRetailPage: false,
  villages: [],
  displayVillageSelector: false,
  villageSelectorOpen: false,
  villageHandler: () => {},
  closeVillageSelector: () => {},
  navHandler: () => {},
  pageLevel: '',
}

export default MenuItem
