import styled, { css } from 'styled-components'
import {
  display,
  zIndex,
  backgroundColor,
  position,
  padding,
  top,
  right,
  height,
  width,
  textAlign,
  left,
  opacity,
} from 'styled-system'
import { Flex, Box, Button } from 'rebass'
import BaseLink from 'components/Link'
import { fill } from 'styles/sharedStyle'

export const Masthead = styled(Flex)`
  ${zIndex}
  ${height}
  ${backgroundColor}
  transition: height .3s ease-in-out, padding-top .3s ease-in-out;
  will-change: height, padding-top;
`

export const SandwichButton = styled(Button)`
  ${position}
  ${padding}
  ${top}
  ${right}
  ${display}
  outline: none;
  z-index: 5;

  * {
    ${fill};
  }

  > span {
    height: 20px;
  }
`

export const Logo = styled(Box)`
  ${display}
  ${position}
  ${width}
  ${textAlign}
  ${left}
  ${opacity}
  * {
    ${fill};
  }
`
export const Link = styled(BaseLink)`
  display: none;

  ${({ logoAmount }) =>
    logoAmount &&
    logoAmount <= 1 &&
    `
    margin: 0 auto;
    display: block;
  `}

  ${({ theme }) => theme.mediaQueries.medium} {
    display: block;
  }

  & + & {
    margin: 0 auto;
    display: block;

    ${({ theme }) => theme.mediaQueries.medium} {
      ${({ logoAmount }) =>
        logoAmount &&
        logoAmount > 1 &&
        css`
          transform: translateX(-50%);
        `}
    }
  }
`
