/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { display } from 'styled-system'
import { Heading as BaseHeading, Text as defaultText } from 'rebass'
import { textTransform, verticalAlign } from 'styles/sharedStyle'
import theme from 'styles/theme'

const BaseText = styled(defaultText)`
  ${verticalAlign}
  ${textTransform}
  ${display}
`

export const Title100 = (props) => {
  const {
    textStyles: { title100 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText as="span" {...title100} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}

export const Title70 = (props) => {
  const {
    textStyles: { title70 },
  } = useTheme()
  return (
    <BaseHeading as="h1" {...title70} {...props}>
      {props.children}
    </BaseHeading>
  )
}

export const Title60 = (props) => {
  const {
    textStyles: { title60 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseHeading as="h2" {...title60} color={fontMainColor} {...props}>
      {props.children}
    </BaseHeading>
  )
}

export const Title42 = (props) => {
  const {
    textStyles: { title42 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseHeading as="h3" {...title42} color={fontMainColor} {...props}>
      {props.children}
    </BaseHeading>
  )
}

export const Title32 = (props) => {
  const {
    textStyles: { title32 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseHeading as="h4" {...title32} color={fontMainColor} {...props}>
      {props.children}
    </BaseHeading>
  )
}

export const Title26 = (props) => {
  const {
    textStyles: { title26 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseHeading as="h5" {...title26} color={fontMainColor} {...props}>
      {props.children}
    </BaseHeading>
  )
}

export const Title20 = (props) => {
  const {
    textStyles: { title20 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseHeading as="h6" {...title20} color={fontMainColor} {...props}>
      {props.children}
    </BaseHeading>
  )
}

export const Title16 = (props) => {
  const {
    textStyles: { title16 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseHeading as="h7" {...title16} color={fontMainColor} {...props}>
      {props.children}
    </BaseHeading>
  )
}
export const Standfirst = (props) => {
  const {
    textStyles: { title20Italics },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText as="p" {...title20Italics} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}
export const Body20 = (props) => {
  const {
    textStyles: { body20 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText as="p" {...body20} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}
export const Body18 = (props) => {
  const {
    textStyles: { body18 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText as="p" {...body18} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}
export const Body16 = (props) => {
  const {
    textStyles: { body16 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText as="p" {...body16} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}
export const Body14 = (props) => {
  const {
    textStyles: { body14 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText as="p" {...body14} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}
export const Body14Div = (props) => {
  const {
    textStyles: { body14 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText as="div" {...body14} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}
export const Body12 = ({ uppercase = false, ...rest }) => {
  const {
    textStyles: { body12Uppercase, body12 },
    colors: { fontMainColor },
  } = useTheme()
  const font = uppercase ? body12Uppercase : body12
  return (
    <BaseText as="p" {...font} color={fontMainColor} {...rest}>
      {rest.children}
    </BaseText>
  )
}

export const Body10 = ({ uppercase = false, ...rest }) => {
  const {
    textStyles: { body10Uppercase, body10 },
    colors: { fontMainColor },
  } = useTheme()
  const font = uppercase ? body10Uppercase : body10
  return (
    <BaseText as="p" {...font} color={fontMainColor} {...rest}>
      {rest.children}
    </BaseText>
  )
}

export const Body8 = ({ uppercase = false, ...rest }) => {
  const {
    textStyles: { body8Uppercase, body8 },
    colors: { fontMainColor },
  } = useTheme()
  const font = uppercase ? body8Uppercase : body8
  return (
    <BaseText as="p" {...font} color={fontMainColor} {...rest}>
      {rest.children}
    </BaseText>
  )
}

export const CtaLabel = (props) => {
  const {
    textStyles: { body12Uppercase },
  } = useTheme()
  return (
    <BaseText as="span" {...body12Uppercase} {...props}>
      {props.children}
    </BaseText>
  )
}

export const Eyebrow = (props) => {
  const {
    textStyles: { body8Uppercase, body10Uppercase },
    colors: { fontMainColor },
  } = useTheme()
  const textProps = props.isMobile ? body8Uppercase : body10Uppercase
  return (
    <BaseText as="p" {...textProps} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}

export const EyebrowCondensed = (props) => {
  const {
    textStyles: { body12UppercaseCondensed },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText
      as="p"
      {...body12UppercaseCondensed}
      color={fontMainColor}
      {...props}>
      {props.children}
    </BaseText>
  )
}

export const Label = (props) => {
  const {
    textStyles: { label },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText
      as="p"
      color={fontMainColor}
      {...{
        ...label,
        ...props,
      }}>
      {props.children}
    </BaseText>
  )
}

export const Divider = styled.span`
  display: inline-block;
  margin: -1px ${theme.space[1]} 0;
`

export const DangerLabel = (props) => {
  const {
    textStyles: { body10Uppercase },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText
      as="p"
      color={fontMainColor}
      {...{
        ...body10Uppercase,
        ...props,
      }}
      style={{
        color: theme.colors.red,
      }}>
      <span
        style={{
          display: 'inline-block',
          fontSize: '14px',
          margin: `-1px ${theme.space[1]} 0`,
        }}>
        ⬩
      </span>
      {props.children}
    </BaseText>
  )
}

export const FormInput = (props) => {
  const {
    textStyles: { body14 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText as="input" {...body14} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}

export const FormDropdown = (props) => {
  const {
    textStyles: { body14 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText as="select" {...body14} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}
export const FormInputTextarea = (props) => {
  const {
    textStyles: { body14 },
    colors: { fontMainColor },
  } = useTheme()
  return (
    <BaseText as="textarea" {...body14} color={fontMainColor} {...props}>
      {props.children}
    </BaseText>
  )
}
