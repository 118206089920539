import React from 'react'
import PropTypes from 'prop-types'
import ButtonPromo from 'components/ButtonPromo'
import { Container, Divider } from 'styles/sharedStyle'
import { useTheme } from 'styled-components'

const FooterSignUp = ({ headline, bodyCopy, ctaCopy, ctaUrl, villageName }) => {
  const {
    colors: { backgroundColor },
  } = useTheme()

  return (
    <>
      <Divider
        as="hr"
        borderColor="alabaster"
        width={[1, null, 0]}
        display={['none', 'block', 'none']}
      />
      <Container
        as="section"
        pt={5}
        borderColor="alabaster"
        borderWidth={['0', '1px 0 0 0', '0']}
        maxWidth={['none', 'none', '380px']}>
        <ButtonPromo
          headline={headline}
          bodyCopy={bodyCopy}
          ctaCopy={ctaCopy}
          ctaUrl={ctaUrl}
          background={backgroundColor}
          inFooter
          italicHeadline
          villageName={villageName}
        />
      </Container>
    </>
  )
}

FooterSignUp.propTypes = {
  headline: PropTypes.string,
  bodyCopy: PropTypes.string,
  ctaCopy: PropTypes.string,
  ctaUrl: PropTypes.string,
  villageName: PropTypes.string,
}

FooterSignUp.defaultProps = {
  headline: '',
  bodyCopy: '',
  ctaCopy: '',
  ctaUrl: '',
  villageName: '',
}

export default FooterSignUp
